.container-cards {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-items: center;
    height: 100%;
    margin: auto;
    margin-top: 60px;
    margin-right: 30px;
}

@media (max-width: 700px) {

    .container-cards {
        grid-template-columns: repeat(1, 1fr);
    }
    

}

