h2 {
    font-size: 28px;
    color: #ffd700;
}

h3 {
    color: #FFFFFF;
    font-size: 18px;
    text-decoration: unset;
    margin: 10px 0;
}

a {
    text-decoration: unset;
}

.projects-page {
    height: 100%;
    overflow: auto !important;
    top: unset;
    padding: 60px 0;
    opacity: 0;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
}

.projects-page h1 {
    text-align: center;
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
}

.projects-page a {
    width: 100%;
}

.card-link {
    position: relative;
    border-radius: 12px;
    width: 100%;
    height: 250px;
    overflow: hidden;
    margin-right: 25px;
    cursor: pointer;
}

.card-link::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7; /* Máscara preta com opacidade */
    transition: all ease 1s;
    z-index: 1;
}

.card-link:hover::before {
    opacity: 0; /* Remove a máscara preta ao passar o mouse */
    transition: all ease 1s;
}

.img-card {
    object-position: top;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Faz a imagem cobrir todo o card */
    z-index: 0;
}

.text-card {
    font-family: "Coolvetica";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    font-size: 24px !important;
    z-index: 2;
    text-align: center;
    transition: opacity ease 1s;
    letter-spacing: 3px;
}

.card-link:hover .text-card {
    opacity: 0; /* Esconde o texto ao passar o mouse */
    transition: opacity ease 1s;
}


@media (max-width: 700px) {

    .projects-page .text-zone {
        position: relative;
        vertical-align: middle;
        max-height: 90%;
        left: 28% !important;
        top: 50% !important;
        width: 65% !important;
    }

    h1 {
        font-size: 36px !important;
        left: 10px;
    }

    .card-link {
        width: 260px;
        height: 250px;
    }

}


