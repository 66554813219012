.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

p {
  font-size: 16px;
  line-height: 23px;
}

.cubespinner {
  margin-top: 200px;
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightyellow;
  }

  .face1 {
    transform: translateZ(100px);
    color: #ec4d28;
  }

  .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #5ed4f4;
  }

  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
  }

  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #f06529;
  }

  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
  }

  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #5ED4F4;
  }
}

@keyframes spincube {

  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }

  16% {
    transform: rotateY(-90deg);
  }

  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }

  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }

  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }

  83% {
    transform: rotateX(90deg);
  }
}

@media (max-width: 700px) {

  .tags {
    left: 90px !important;
  }

  .stage-cube-cont {
    width: 80%;
  }

  .cubespinner {
    margin-top: 60px;
  }

  .about-page {
    .text-zone {
      left: 30% !important;
      top: 71% !important;
      width: 65% !important;

      h1 {
        font-size: 36px !important;
        left: 10px;
      }
  
      p {
        font-size: 12px !important;
        line-height: 23px !important;
      }
    }
  }

}